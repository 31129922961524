.visualizations__container {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(6, minmax(50px, auto));
    justify-content: center;
    align-items: center;
}

.visualizations__tab {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column: 1/3;
    grid-row: 1/2;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 15px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #0336FF;
}

.visualizations__menu-items {
    display: grid;
    margin: 2px;
    padding: 5px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}

.visualizations__menu-items-selected {
    display: grid;
    margin: 2px;
    padding: 5px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    color: #000000;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 700;
    background-color: #FFDE03;
    cursor: pointer;
}

.visualizations__user-profile {
    display: flex;
    justify-content: space-evenly;
    grid-column: 8/10;
    grid-row: 1/2;
    align-items: center;
    padding: 10px;
    margin: 15px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #FFDE03;
    cursor: pointer;
}

.visualizations__user-profile:hover {
    transform: scale(0.9);
}

.visualizations__user-name {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
}

.visualizations__user-profile-detail {
    grid-column: 8/10;
    grid-row: 2/4;
    padding: 10px;
    margin: 15px;
    margin-top: 0px;
    z-index: 100;
    box-shadow:  5px 5px 5px rgb(110, 110, 110);
    border-radius: 10px;
    border: 1px solid rgb(110, 110, 110);
    background-color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 400;
    text-transform: capitalize;

}