.layout__container {
   display: grid;
   grid-template-columns: repeat(9, 1fr);
   grid-template-rows: repeat(3, minmax(10vh, auto));
   grid-gap: 10px;
   margin: 0 auto;
}

.layout__container::-webkit-scrollbar {display:none;}

header {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column: 1/12;
    grid-row: 1/2;
    /* justify-items: center; */
    align-items: center;
    background-color: #0336FF;
}

.layout__heading {
    grid-column: 1/4;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 32px;
    padding-left: 40px;
    color: #FFFFFF;
}

.layout__upload {
    grid-column: 12/13;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
    align-items: center;
    cursor: pointer;
}

.layout__upload:hover {
    transform: scale(0.9);
}

body {
    grid-column: 1/10;
    grid-row: 2/4;
    min-height: 80vh;
}

footer {
    display: grid;
    grid-column: 1/10;
    grid-row: 4/5;
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    opacity: 0.75;
}

