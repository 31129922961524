.visualizations__analytics {
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(6, minmax(50px, auto));
    grid-column: 1/10;
    grid-row: 2/6;
    min-height: 55vh;
}

.analytics__wrapper {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
}

.analytics__tab {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column: 1/3;
    grid-row: 1/2;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 15px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #0336FF;
}

.analytics__menu-items {
    display: grid;
    margin: 2px;
    padding: 5px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}

.analytics__menu-items-selected {
    display: grid;
    margin: 2px;
    padding: 5px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    color: #000000;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 700;
    background-color: #FFDE03;
    cursor: pointer;
}

.upper__tab {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column: 3/7;
    grid-row: 1/2;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 15px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #0336FF;
}

.lower__tab {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column: 3/7;
    grid-row: 1/2;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 15px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #0336FF;
}