
.visualizations__games {
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(6, minmax(50px, auto));
    grid-column: 1/10;
    grid-row: 2/6;
    min-height: 55vh;
}

.games__tab {
    display: grid;
    grid-template-columns: repeat(15, 1fr);
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin: 15px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #0336FF;
}

.recharts-responsive-container {
    grid-column: 2/9;
    grid-row: 2/5;
    min-height: 45vh;
}