.upload__container {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(6, minmax(50px, auto));
    justify-content: center;
    align-items: center;
}

.upload__wrapper {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, minmax(25px, auto));
    grid-column: 2/9;
    grid-row: 2/6;
    padding: 20px;
    border-radius: 20px;
    margin: 20px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.upload_data_style {
    box-shadow: 0px 3px 21px #0000000F;
    border-radius: 20px;
}


.content-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 20px 40px 20px 40px;
    box-shadow: 0px 3px 21px #0000000F;
    border-radius: 20px;
    font-family: 'Poppins', sans-serif;
}

.content-upload-title {
    font-size: 20px;
    font-weight: bold;
    padding: 5px;
}

.content-upload-subtitle {
    font-size: 14px;
    font-weight: normal;
    color: #707070;
    padding: 5px;
    white-space: pre-line;
    text-align: center;
}

.content-upload-file::-webkit-file-upload-button {
    visibility: hidden;
}

.content-upload-file {
    background: url(./../assets/images/upload.png) no-repeat center center;
    width: 60px;
    height: 60px;
    padding: 50px;
    cursor: pointer;
}

.content-upload-file-btn {
    background-color: #0335ffe5;
    font-size: 16px;
    font-weight: lighter;
    color: white;
    padding: 10px;
    border-radius: 15px;
    margin: 10px;
    cursor: pointer;
}

.content-upload-file-btn:hover {
    transform: scale(0.95)
}  


.upload__help {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
    opacity: 0.75;
}

.ant-table-wrapper {
    grid-column: 1/4;
    overflow-x: scroll;
    overflow-y: hidden;
    margin: 20px;
}

.ant-table-cell {
    min-width: 100px;
    margin: 2px;
    padding: 2px;
}

.ant-btn {
    grid-column: 8/9;
    margin: 20px;
    font-family: 'Poppins', sans-serif;
}

input#file-upload-button {
    font-family: 'Poppins', sans-serif !important;
}